import cx from 'classnames'

export default function RebateBadge({
  rebate,
  className,
}: {
  rebate: string
  className?: string
}) {
  const rebateTextSizeClass =
    (rebate?.length || 0) > 2 ? 'text-sm' : 'text-base'
  return (
    <div
      className={cx(
        'bg-blue-12 text-white text-center rounded-full w-10 h-10',
        className
      )}
    >
      <div
        className={cx(
          'absolute bottom-[35%] font-bold w-full',
          rebateTextSizeClass
        )}
      >
        {rebate}
      </div>
      <div className="absolute top-[50%] font-medium text-xs w-full">OFF</div>
    </div>
  )
}
