import { AxiosInstance } from 'axios'
import {
  GetBlueprintAndVariants,
  GetBlueprintAnvVariantsResponse,
  GetImagesForPreview,
  GetImagesForPreviewResponse,
  GetPaymentIntent,
} from './types/printify'

export class PrintifyApi {
  api: AxiosInstance

  constructor(api: AxiosInstance) {
    this.api = api
  }

  getPaymentIntent: GetPaymentIntent = async (id) => {
    const { data } = await this.api.get(`/v1/printify/payment/${id}`)
    return data
  }

  getBlueprintsAndVariants: GetBlueprintAndVariants = async (
    blueprintId: number
  ) => {
    const { data } = await this.api.get<GetBlueprintAnvVariantsResponse>(
      `/v1/printify/blueprints/${blueprintId}`
    )

    return data
  }

  getImagesForPreview: GetImagesForPreview = async (payload) => {
    const {
      data: { productId, images },
    } = await this.api.post<GetImagesForPreviewResponse>(
      `/v1/printify/generate-preview`,
      payload
    )

    return {
      images,
      productId,
    }
  }
}
