import { FC } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Button from '../../../shared/buttons/Button'
import Socks5UsdOff from '../../../assets/grandparents_day/divvy_up/socks_5_usd_off.svg?react'
import CopyToClipboardIcon from '../../../assets/copy-to-clipboard-icon.svg?react'
import copyCouponContourBg from '../../../assets/grandparents_day/divvy_up/copy_coupon_contour_bg.png'

const CouponCodeSection: FC<{
  couponCode?: string
  onCopy: () => void
}> = ({ couponCode, onCopy }) => (
  <>
    {couponCode && (
      <section
        className="bg-cover p-6 text-center"
        style={{
          backgroundImage: `url(${copyCouponContourBg})`,
        }}
      >
        <div className="bg-white rounded-2xl px-2 py-8">
          <h3 className="font-bold text-2xl mb-2">
            Exclusive Offer for FamilyAlbum Users!
          </h3>
          <Socks5UsdOff className="mx-auto mb-4" />
          <p className="mb-6">
            Stacks with site-wide 20% off.
            <br />
            $5 OFF* each order with promo code:
          </p>
          <CopyToClipboard text={couponCode} onCopy={onCopy}>
            <Button
              colorVariant="primary:invert"
              className="rounded-full text-s px-4 py-2 flex items-center mx-auto"
            >
              <CopyToClipboardIcon />
              {couponCode}
            </Button>
          </CopyToClipboard>
          <p className="text-sm mt-6">
            *$5 OFF applies to orders over $10. Excludes gift cards & discounted
            bulk logo sock orders.
          </p>
        </div>
      </section>
    )}
  </>
)

export default CouponCodeSection
