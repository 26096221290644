import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useCallback, useEffect, useState } from 'react'
import {
  CreateCheckoutSession,
  GetCheckoutSession,
} from '../../../clients/fagl-server/types/photoProductsCart'
import Success from './Success'
import CheckoutProgressBar from '../components/CheckoutProgressBar'
import LoadingOverlay from '../components/LoadingOverlay'

const stripePromise = loadStripe(import.meta.env.VITE_PRINTIFY_STRIPE_KEY)

export default function StripeCheckoutPage({
  createCheckoutSession,
  getCheckoutSession,
  priceFormatter,
  onBackClick,
  onCompleteCheckout,
}: {
  createCheckoutSession: CreateCheckoutSession
  getCheckoutSession: GetCheckoutSession
  priceFormatter: (price: number) => string
  onBackClick: () => void
  onCompleteCheckout: () => void
}) {
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [secret, setSecret] = useState('')
  const [checkoutSessionId, setCheckoutSessionId] = useState('')
  const [payment, setPayment] = useState<Awaited<
    ReturnType<GetCheckoutSession>
  > | null>(null)

  const getSecret = useCallback(async () => {
    setIsLoading(true)
    const { clientSecret, checkoutSessionId } = await createCheckoutSession()
    setCheckoutSessionId(checkoutSessionId)
    setSecret(clientSecret)
    setIsLoading(false)
    return clientSecret
  }, [createCheckoutSession])
  const onComplete = useCallback(async () => {
    const response = await getCheckoutSession(checkoutSessionId)
    setPayment(response)
    setIsSuccess(true)
    onCompleteCheckout()
  }, [setIsSuccess, checkoutSessionId, onCompleteCheckout])
  useEffect(() => {
    getSecret()
  }, [])
  return (
    <div className="h-screen bg-gray-2 relative flex flex-col">
      <div className="shrink-0 grow-0 py-4">
        <CheckoutProgressBar
          onBackClick={isSuccess ? undefined : onBackClick}
          orderedSteps={['Cart', 'Payment', 'Complete']}
          activeStepNumber={isSuccess ? 3 : 2}
        />
      </div>
      {isLoading ? (
        <LoadingOverlay animationType="spinner">
          Creating a secure payment session
        </LoadingOverlay>
      ) : isSuccess ? (
        <Success
          helpEmailSubject="Order Success"
          supportEmailAddress="global-support@family-album.com"
          chargedAmount={priceFormatter((payment?.amount ?? 0) / 100)}
          email={payment?.email || ''}
        />
      ) : (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{
            onComplete,
            clientSecret: secret,
          }}
        >
          <EmbeddedCheckout className="bg-gray-2" />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  )
}
