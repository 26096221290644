import { FC } from 'react'
import socksHeaderImage from '../../../assets/fathers_day/special/fathersday_lp_socks_header_image.png'
import FathersDayLpHeaderTitle from '../../../assets/fathers_day/special/fathersday_lp_socks_header_title.svg?react'
import LinkButton from '../../../shared/buttons/LinkButton'
import DetailedCountdown from '../../../shared/DetailedCountdown'
import { C2AMetadata } from '../../../hooks/useRecordUserAction'

const SocksHeader: FC<{
  deadline: Date
  onCtaClick: (metadata: C2AMetadata) => void
  toPath: string
}> = ({ deadline, onCtaClick, toPath }) => {
  return (
    <section className="bg-[#CCF3EA]">
      <div className="w-full mx-auto pt-8 ">
        <FathersDayLpHeaderTitle className="mx-auto mb-4" />
        <img
          src={socksHeaderImage}
          alt="Father's Day special"
          className="mx-auto w-full"
        />
      </div>
      <LinkButton
        onClick={() => onCtaClick({ clickedElement: 'LP_HEADER' })}
        className="block p-2 text-center w-11/12 mx-auto mt-2 rounded-md"
        colorVariant="primary"
        to={toPath}
      >
        Order Custom Socks
      </LinkButton>
      <div className="w-full text-center p-4">
        <div className="font-bold text-base">Socks Sale ends in</div>
        <DetailedCountdown
          deadline={deadline}
          variant="cards"
          style={{
            color: '#000000',
            width: '100%',
          }}
        />
      </div>
    </section>
  )
}

export default SocksHeader
