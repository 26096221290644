import ShoppingCartIcon from '../../../shared/icons/ShoppingCartIcon'

export default function ShoppingCartBadge({
  numberOfItemsInCart,
}: {
  numberOfItemsInCart: number
}) {
  return (
    <div className="relative">
      <ShoppingCartIcon className="w-6" />
      {numberOfItemsInCart > 0 && (
        <div className="text-1xs absolute -right-2 -top-2 rounded-full flex items-center justify-center w-4 h-4 font-bold bg-mi-primary text-white">
          {numberOfItemsInCart}
        </div>
      )}
    </div>
  )
}
