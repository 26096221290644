import { useCallback, useEffect, useMemo, useState } from 'react'
import { Route, Routes, useSearchParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import ApiClient from '../../ApiClient'
import CreateFlow from './CreateFlow'
import TermsOfUsePanel from './components/TermsOfUsePanel'
import { useNavigate } from 'react-router-dom'
import PhotoGiftsProductList from '../../shared/PhotoGiftsProductList'
import {
  AddItemToCartPayload,
  Cart,
} from '../../clients/fagl-server/types/photoProductsCart'
import CartPage from './Checkout/Cart'
import { create2DecimalsCurrencyFormatter } from '../../utils'
import StripeCheckoutPage from './Checkout/StripeCheckout'
import useRecordUserAction from '../../hooks/useRecordUserAction'
import ConfirmationDialog from '../../shared/ConfirmationDialog'
import LoadingOverlay from './components/LoadingOverlay'
import ProductWrapper from './components/ProductWrapper'

const MAX_QUANTITY = 10

export default function PhotoProductsApp({
  api,
  isPrintifyPreviewLogsActive,
  sourcePath,
}: {
  api: ApiClient
  isPrintifyPreviewLogsActive: boolean
  sourcePath: string
}) {
  const navigate = useNavigate()

  const {
    photoProducts: {
      recordDidAddProductToCart,
      recordDidTapShoppingCart,
      recordDidTapToGetToCheckout,
      recordDidAbandonCart,
      recordDidResumeCart,
      recordDidCompleteCheckout,
    },
  } = useRecordUserAction(api)

  const [searchParams] = useSearchParams()
  const [uuid] = useState(searchParams.get('uuid') || '')
  const sourcePathWithUuid = useMemo(() => `${sourcePath}?uuid=${uuid}`, [uuid])

  const [isCartLoaded, setIsCartLoaded] = useState(false)
  const [isCartAlreadyResumedInSession, setIsCartAlreadyResumedInSession] =
    useState(false)
  const [cart, setCart] = useState<Cart | null>(null)
  const [editedCartItemId, setEditedCartItemId] = useState<number | null>(null)
  const [isCartResumeConfirmationVisible, setIsCartResumeConfirmationVisible] =
    useState(false)

  const cartItems = cart?.items || []
  const editedCartItem = useMemo(() => {
    return cartItems.find((item) => item.id === editedCartItemId)
  }, [cartItems, editedCartItemId])

  const [isTermsOfUsePageVisible, setIsTermsOfUsePageVisible] = useState(false)

  const navigateBackToSource = useCallback(() => {
    navigate(sourcePathWithUuid)
  }, [navigate, sourcePath, uuid])

  const priceFormatter = useMemo(
    () => create2DecimalsCurrencyFormatter('usd'),
    []
  )

  const getCart = useCallback(async () => {
    try {
      const response = await api.photoProductsCart.getCart()
      if (response) {
        if (!isCartAlreadyResumedInSession) {
          setIsCartResumeConfirmationVisible(true)
        }
        setCart(response)
      }
    } catch (error) {
      console.error(error)
    } finally {
      // In the off chance a cart could not load, we still want to show the page
      setIsCartLoaded(true)
    }
  }, [api, isCartAlreadyResumedInSession])

  const addItemToCart = useCallback(
    async (payload: AddItemToCartPayload) => {
      recordDidAddProductToCart({
        numberOfProductsInCart: cartItems.length + 1,
        productVendor: payload.provider,
        blueprintId: payload.metadata.blueprintId,
        productName: payload.metadata.title,
      })
      if (editedCartItem) {
        await api.photoProductsCart.deleteCartItem(editedCartItem.id)
        setEditedCartItemId(null)
      }
      try {
        const response = await api.photoProductsCart.addItemToCart(payload)
        setCart(response)

        navigate('/photo-products/cart')
      } catch (error) {
        toast.error('Failed to add item to cart')
        console.error(error)
      }
    },
    [api, editedCartItem, recordDidAddProductToCart, cartItems.length]
  )

  const abandonCart = useCallback(async () => {
    recordDidAbandonCart({
      numberOfProductsInCart: cartItems.length,
      cartId: cart?.id,
    })
    await api.photoProductsCart.abandonCart()
    setCart(null)
  }, [api, cartItems.length, cart?.id])

  const resumeCart = useCallback(async () => {
    recordDidResumeCart({
      numberOfProductsInCart: cartItems.length,
      cartId: cart?.id,
    })
    const response = await api.photoProductsCart.resumeCart()
    setCart(response)
  }, [api, cartItems.length, cart?.id])

  const updateItemQuantity = useCallback(
    async (itemId: number, quantity: number) => {
      try {
        const updatedCart = await api.photoProductsCart.updateItemCartQuantity(
          itemId,
          {
            quantity,
          }
        )
        setCart(updatedCart)
      } catch (error) {
        toast.error('Failed to update item quantity')
        console.error(error)
      }
    },
    [api, cart, getCart]
  )

  const deleteCartItem = useCallback(
    async (itemId: number) => {
      try {
        const updatedCart = await api.photoProductsCart.deleteCartItem(itemId)
        setCart(updatedCart)
      } catch (error) {
        toast.error('Failed to remove item from cart')
        console.error(error)
      }
    },
    [api, cart, getCart]
  )

  const editItem = useCallback((itemId: number, blueprintId: number) => {
    setEditedCartItemId(itemId)
    navigate(`/photo-products/add/${blueprintId}/personalize`)
  }, [])

  const cancelCartItemEdit = useCallback(() => {
    setEditedCartItemId(null)
    navigate('/photo-products/cart')
  }, [])

  const navigateToCheckoutFromCart = useCallback(() => {
    recordDidTapToGetToCheckout({
      numberOfProductsInCart: cartItems.length,
    })
    navigate('/photo-products/checkout')
  }, [navigate, cartItems.length])

  const onShoppingCartClick = useCallback(() => {
    recordDidTapShoppingCart({
      numberOfProductsInCart: cartItems.length,
    })
  }, [cartItems.length])

  const onCartAbandonClick = useCallback(
    (source?: 'GENERIC' | 'CANCEL') => {
      if (source === 'GENERIC') {
        return
      } else {
        setIsCartResumeConfirmationVisible(false)
        abandonCart()
      }
    },
    [abandonCart]
  )

  const onCartResumeClick = useCallback(() => {
    setIsCartResumeConfirmationVisible(false)
    setIsCartAlreadyResumedInSession(true)
    resumeCart()
  }, [resumeCart])

  const onCompleteCheckout = useCallback(() => {
    recordDidCompleteCheckout({
      cartId: cart?.id,
      numberOfProductsInCart: cartItems.length,
    })
  }, [cartItems.length, cart?.id])

  useEffect(() => {
    getCart()
  }, [getCart])

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <PhotoGiftsProductList
              api={api}
              onShoppingCartClick={onShoppingCartClick}
              numberOfItemsInCart={cartItems.length}
              printifyProductsListLink="/photo-products"
            />
          }
        />
        <Route
          path="/cart"
          element={
            <CartPage
              editItem={editItem}
              updateItemQuantity={updateItemQuantity}
              deleteCartItem={deleteCartItem}
              maxQuantity={MAX_QUANTITY}
              next={navigateToCheckoutFromCart}
              cart={cart}
              priceFormatter={priceFormatter}
              navigateBackToSource={navigateBackToSource}
            />
          }
        />
        <Route
          path="/checkout"
          element={
            <StripeCheckoutPage
              onBackClick={() => navigate('/photo-products/cart')}
              createCheckoutSession={
                api.photoProductsCart.createCheckoutSession
              }
              onCompleteCheckout={onCompleteCheckout}
              getCheckoutSession={api.photoProductsCart.getCheckoutSession}
              priceFormatter={priceFormatter}
            />
          }
        />
        <Route
          path="/add/:productId/*"
          element={
            <ProductWrapper>
              {({ product, variants }) => (
                <CreateFlow
                  currency="usd"
                  product={product}
                  variants={variants}
                  cancelCartItemEdit={cancelCartItemEdit}
                  addItemToCart={addItemToCart}
                  editedCartItem={editedCartItem ?? null}
                  api={api}
                  navigateBackToSource={navigateBackToSource}
                  openTermsOfUsePage={() => setIsTermsOfUsePageVisible(true)}
                  isPrintifyPreviewLogsActive={isPrintifyPreviewLogsActive}
                />
              )}
            </ProductWrapper>
          }
        />
      </Routes>
      <TermsOfUsePanel
        isOpen={isTermsOfUsePageVisible}
        close={() => setIsTermsOfUsePageVisible(false)}
      />
      <ConfirmationDialog
        isOpen={isCartResumeConfirmationVisible}
        confirm={onCartResumeClick}
        close={onCartAbandonClick}
        hideCloseButton
        confirmButtonText="Resume Shopping"
        cancelButtonText="Start Fresh"
        message="It looks like you have items left in your shopping cart from a previous session. Would you like to:"
      />
      {!isCartLoaded && <LoadingOverlay opaque animationType="spinner" />}
    </>
  )
}
