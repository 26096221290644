import { ReactNode, useCallback, useMemo, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import SelectPhotoPage from './SelectPhoto'
import ProductPage from './Product'
import PersonalizePage from './Personalize'
import PreviewPage from './Preview'
import LoadingOverlay from './components/LoadingOverlay'
import ApiClient from '../../ApiClient'
import { create2DecimalsCurrencyFormatter } from '../../utils'
import usePrintify from './usePrintify'
import useRecordUserAction from '../../hooks/useRecordUserAction'
import LogsPanel from './LogsPanel'
import {
  AddItemToCartPayload,
  addItemToCartPayloadSchema,
  CartItem,
} from '../../clients/fagl-server/types/photoProductsCart'
import useCrop from './hooks/useCrop'
import {
  PrintifyBlueprint,
  MergedVariantMetadata,
} from '../../clients/fagl-server/types/printify'

export default function CreateFlow({
  api,
  editedCartItem,
  openTermsOfUsePage,
  navigateBackToSource,
  isPrintifyPreviewLogsActive,
  addItemToCart,
  cancelCartItemEdit,
  product,
  variants,
  currency = 'USD',
}: {
  api: ApiClient
  editedCartItem: CartItem | null
  openTermsOfUsePage: () => void
  navigateBackToSource: () => void
  isPrintifyPreviewLogsActive: boolean
  addItemToCart: (payload: AddItemToCartPayload) => void
  cancelCartItemEdit: () => void
  product: PrintifyBlueprint
  variants: MergedVariantMetadata[]
  currency: string
}) {
  const defaultVariantId = variants[0].id

  const {
    generatePreview,
    updateQuantityAndVariant,
    previewPhotosList,
    isGettingPreview,
    showNotAllLoadedError,
    regeneratePreview,
    fetchLogs,
    createPayloadWithVariant,
    resetState,
    variantId,
    quantity,
    sourceImages,
    isPreparingEdit,
  } = usePrintify({
    blueprintId: product.id,
    defaultVariantId,
    editedCartItem,
  })
  const isEditMode = !!editedCartItem
  const navigate = useNavigate()
  const variantsById = useMemo(
    () => Object.fromEntries(variants.map((v) => [v.id, v])),
    [variants]
  )

  const variant = variantsById[variantId]

  const priceFormatter = useMemo(
    () => create2DecimalsCurrencyFormatter(currency),
    [currency]
  )

  const formattedPrices = useMemo(() => {
    const zeroPrice = `${priceFormatter(0)} ${currency}`
    return {
      price: variant?.price
        ? `${priceFormatter(variant.price / 100)} ${currency}`
        : zeroPrice,
      salePrice: variant?.salePrice
        ? `${priceFormatter(variant.salePrice / 100)} ${currency}`
        : zeroPrice,
    }
  }, [priceFormatter, currency])

  const {
    photoProducts: {
      printify: { recordDidTapPersonalize, recordDidTapPreview },
    },
  } = useRecordUserAction(api)

  const [isAddingItemToCart, setIsAddingItemToCart] = useState(false)
  const [isLogsPageVisible, setIsLogsPageVisible] = useState(false)

  const {
    croppedDataUrl,
    isCropping,
    initCrop,
    resetCrop,
    confirmCrop,
    openCropDialog,
    closeCropDialog,
    urls,
  } = useCrop(
    sourceImages[0]
      ? {
          original: sourceImages[0]?.originalUrl ?? null,
          cropped: sourceImages[0]?.usedUrl ?? null,
          candidate: sourceImages[0]?.originalUrl ?? null,
        }
      : null
  )

  const onPhotoSelected = useCallback(
    (base64: string) => {
      initCrop(base64)
      navigate('personalize')
    },
    [navigate]
  )

  const onCropConfirmed = useCallback(
    (base64: string) => {
      confirmCrop(base64)
      navigate('personalize')
    },
    [navigate, urls.candidate, confirmCrop]
  )

  const navigateToPayment = useCallback(
    (path: string = '') => {
      navigate(`payment${path}`)
    },
    [navigate]
  )

  const onPersonalizeCompleted = useCallback(
    async (variantId: number) => {
      recordDidTapPreview({
        blueprintId: product.id,
      })

      if (!croppedDataUrl) {
        return
      }

      navigate('preview')
      const payload = {
        variant: variantsById[variantId],
        newUsedImageBase64: croppedDataUrl,
        usedImageDataUrls: [
          {
            original: urls.original ?? '',
            cropped: urls.cropped ?? '',
          },
        ],
      }

      await generatePreview(payload)
    },
    [
      generatePreview,
      navigate,
      recordDidTapPreview,
      product.id,
      navigateToPayment,
      croppedDataUrl,
      variantsById,
    ]
  )

  const onPreviewCompleted = useCallback(async () => {
    const result = addItemToCartPayloadSchema.safeParse(
      createPayloadWithVariant(variant)
    )

    if (result.success) {
      setIsAddingItemToCart(true)
      await addItemToCart(result.data)
      resetState()
      setIsAddingItemToCart(false)
    } else {
      console.error(result.error)
    }
  }, [product.id, createPayloadWithVariant, resetState, variant])

  const onProductSelectionCompleted = useCallback(
    (payload: { quantity: number; variantId: number }) => {
      updateQuantityAndVariant(payload.quantity, payload.variantId)
      recordDidTapPersonalize({
        blueprintId: product.id,
      })
      if (croppedDataUrl) {
        navigate('personalize')
      } else {
        navigate('select-photo')
      }
    },
    [
      navigate,
      updateQuantityAndVariant,
      recordDidTapPersonalize,
      croppedDataUrl,
      product.id,
    ]
  )

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <ProductPage
              openTermsOfUsePage={openTermsOfUsePage}
              price={formattedPrices.price}
              salePrice={formattedPrices.salePrice}
              initialQuantity={quantity}
              product={product}
              variantTitle={variant.title}
              variants={variants}
              initialVariantId={variantId}
              next={onProductSelectionCompleted}
              previous={navigateBackToSource}
              updateQuantityAndVariant={updateQuantityAndVariant}
            />
          }
        />
        <Route
          path="select-photo"
          element={
            <SelectPhotoPage
              next={onPhotoSelected}
              previous={() => navigate('.')}
            />
          }
        />
        <Route
          path="personalize"
          element={
            <PersonalizePage
              key={variantId}
              isPreparingEdit={isPreparingEdit}
              isEditMode={isEditMode}
              cancelCartItemEdit={cancelCartItemEdit}
              onNewPhotoSelected={resetCrop}
              urls={urls}
              isCropping={isCropping}
              aspectRatio={variant.aspectRatio}
              confirmCrop={onCropConfirmed}
              openCropDialog={openCropDialog}
              closeCropDialog={closeCropDialog}
              next={onPersonalizeCompleted}
              initialVariantId={variantId}
              variants={variants}
              previous={() => navigate('select-photo')}
            />
          }
        />
        <Route
          path="preview"
          element={
            <PreviewPage
              isGettingPreview={isGettingPreview}
              isAddingItemToCart={isAddingItemToCart}
              isPrintifyPreviewLogsActive={isPrintifyPreviewLogsActive}
              openLogsPage={() => setIsLogsPageVisible(true)}
              showNotAllLoadedError={showNotAllLoadedError}
              previewPhotosList={previewPhotosList}
              previous={() => navigate('personalize')}
              next={onPreviewCompleted}
              regeneratePreview={regeneratePreview}
            />
          }
        />
      </Routes>
      {isPrintifyPreviewLogsActive && (
        <LogsPanel
          logs={fetchLogs}
          isOpen={isLogsPageVisible}
          close={() => setIsLogsPageVisible(false)}
        />
      )}
    </>
  )
}
