import { createContext, useContext } from 'react'
import ApiClient from '../ApiClient'

const ApiContext = createContext<{ api: ApiClient | null }>({ api: null })

export const ApiProvider = ApiContext.Provider

export const useApi = () => {
  const context = useContext(ApiContext)
  if (!context) {
    throw new Error('useApi must be used within an ApiProvider')
  }

  if (!context.api) {
    throw new Error('ApiProvider is not set')
  }

  const api = context.api

  return { api }
}
