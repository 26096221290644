import { MergedVariantMetadata } from '../../../clients/fagl-server/types/printify'

export default function VariantSizeSelector({
  variants,
  updateVariantId,
  selectedVariantId,
}: {
  variants: MergedVariantMetadata[]
  updateVariantId: (variantId: number) => void
  selectedVariantId: number
}) {
  return (
    <div className="flex flex-row gap-6">
      <span>Pcs:</span>
      <select
        className="border border-solid p-1 transition-all border-fa-border"
        value={selectedVariantId || variants[0].id}
        onChange={(e) => updateVariantId(Number(e.target.value))}
      >
        {variants.map((variant) => (
          <option key={variant.id} value={variant.id}>
            {variant.options.size}
          </option>
        ))}
      </select>
    </div>
  )
}
