export function b64Encode(str: string) {
  return btoa(str)
}

export const isMaintenanceTimeInEST = () => {
  const maintenanceStartTime = new Date('2024-02-13T16:00:00.000-00:00')
  const maintenanceEndTime = new Date('2024-02-13T19:00:00.000-00:00')
  const currentTime = new Date()

  return (
    currentTime >= maintenanceStartTime && currentTime <= maintenanceEndTime
  )
}

export const createCurrencyFormatter = (currency: string) =>
  new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'currency',
    currency,
  }).format

export const create2DecimalsCurrencyFormatter = (currency: string) =>
  new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: 'currency',
    currency,
  }).format

export function isDeviceIOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export function isDeviceAndroid() {
  return navigator.userAgent.includes('Android')
}

export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      if (!fileReader.result) {
        reject('FileReader result is empty')
        return
      }
      resolve(fileReader.result?.toString())
    }

    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}
