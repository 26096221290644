import { useCallback, useState } from 'react'
import {
  FaglVariantMetadata,
  MergedVariantMetadata,
  PrintifyBlueprint,
  PrintifyVariantProvider,
} from '../../../clients/fagl-server/types/printify'
import { useApi } from '../../../hooks/useApi'
import { isAxiosError } from 'axios'
import { useRollbar } from '@rollbar/react'

function flatMapVariantsFromProviders(
  providers: PrintifyVariantProvider[],
  variants: FaglVariantMetadata[]
): MergedVariantMetadata[] {
  const variantsById = Object.fromEntries(
    variants.map((variant) => [variant.id, variant])
  )
  return providers.flatMap((provider) =>
    provider.variants.map((variant) => ({
      ...variant,
      providerId: provider.id,
      ...variantsById[variant.id],
    }))
  )
}

function createPhotoUrlsForBlueprintId(blueprintId: number, number: number) {
  const urls = []
  for (let i = 1; i <= number; i++) {
    urls.push(`/photo-products/${blueprintId}_${i}.jpg`)
  }
  return urls
}

const blueprintIdToPhotoUrls: Record<number, string[]> = {
  68: createPhotoUrlsForBlueprintId(68, 4),
  425: createPhotoUrlsForBlueprintId(425, 4),
  616: createPhotoUrlsForBlueprintId(616, 4),
  1115: createPhotoUrlsForBlueprintId(1115, 4),
  1126: createPhotoUrlsForBlueprintId(1126, 4),
  1151: createPhotoUrlsForBlueprintId(1151, 4),
}

export function useProduct() {
  const { api } = useApi()
  const rollbar = useRollbar()
  const [isLoading, setIsLoading] = useState(true)
  const [product, setProduct] = useState<PrintifyBlueprint | null>(null)
  const [loadError, setLoadError] = useState<string>('')
  const [variants, setVariants] = useState<MergedVariantMetadata[]>([])

  const load = useCallback(
    async (id: number) => {
      setLoadError('')
      try {
        const { blueprint: loadedBlueprint, variantProviders } =
          await api.printify.getBlueprintsAndVariants(id)
        const variants = flatMapVariantsFromProviders(
          variantProviders,
          Object.values(loadedBlueprint.variants)
        )

        setProduct({
          ...loadedBlueprint,
          images: blueprintIdToPhotoUrls[id],
        })
        setVariants(variants)
      } catch (err) {
        const error = err as Error
        if (isAxiosError(err)) {
          if (err.response?.status === 404) {
            setLoadError('NOT_FOUND')
          }
        } else {
          console.error(err)
          rollbar.error('unexpected error loading Printify blueprint', error)
          setLoadError(error.message)
        }
      } finally {
        setIsLoading(false)
      }
    },
    [product, setProduct, variants, setVariants]
  )

  return {
    isLoading,
    product,
    loadError,
    load,
    variants,
  }
}
