import cx from 'classnames'

export default function Footer({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <section className={cx('relative', className)}>
      <div className="shadow-line" />
      <div className="p-2 text-center bg-white">{children}</div>
    </section>
  )
}
