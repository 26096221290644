import { useCallback, useEffect, useState } from 'react'

export default function useCrop(
  initialUrls: {
    candidate: string | null
    cropped: string | null
    original: string | null
  } | null
) {
  const [confirmedOriginalDataUrl, setOriginalDataUrl] = useState<
    string | null
  >(initialUrls?.original ?? null)
  const [croppedDataUrl, setCroppedDataUrl] = useState<string | null>(
    initialUrls?.cropped ?? null
  )
  const [candidateDataUrl, setCandidateDataUrl] = useState<string | null>(
    initialUrls?.candidate ?? null
  )

  const [isCropping, setIsCropping] = useState(false)

  const init = useCallback(
    (base64: string) => {
      setOriginalDataUrl(base64)
      setCandidateDataUrl(base64)
      setIsCropping(true)
    },
    [setOriginalDataUrl]
  )

  const confirmCrop = useCallback(
    (base64url: string) => {
      setCroppedDataUrl(base64url)
      setOriginalDataUrl(candidateDataUrl)
      setIsCropping(false)
    },
    [, candidateDataUrl, setOriginalDataUrl]
  )

  const openCropDialog = useCallback(() => {
    setIsCropping(true)
  }, [setIsCropping])

  const closeCropDialog = useCallback(() => {
    setIsCropping(false)
    setCandidateDataUrl(null)
    if (!croppedDataUrl && confirmedOriginalDataUrl) {
      setOriginalDataUrl(null)
    }
  }, [setIsCropping, croppedDataUrl, confirmedOriginalDataUrl])

  const reset = useCallback(async (base64: string) => {
    setCandidateDataUrl(base64)
    setIsCropping(true)
  }, [])

  useEffect(() => {
    if (initialUrls) {
      setCroppedDataUrl(initialUrls.cropped)
      setOriginalDataUrl(initialUrls.original)
      setCandidateDataUrl(initialUrls.candidate)
    }
  }, [initialUrls])

  return {
    initCrop: init,
    croppedDataUrl,
    isCropping,
    candidateDataUrl,
    setIsCropping,
    confirmCrop,
    resetCrop: reset,
    openCropDialog,
    closeCropDialog,
    urls: {
      candidate: candidateDataUrl,
      cropped: croppedDataUrl,
      original: confirmedOriginalDataUrl,
    },
  }
}
