import axios from 'axios'
import { useEffect, useState } from 'react'
import Markdown from 'react-markdown'
import { useNavigate, useParams } from 'react-router-dom'
import StickyFooter from '../../shared/StickyFooter'
import Button from '../../shared/buttons/Button'

const GcTerms = () => {
  const navigate = useNavigate()
  const { currencyCode } = useParams<{ currencyCode: string }>()
  const [content, setContent] = useState('')
  const loadMarkdownn = async () => {
    const file = await axios.get(
      `/markdown/fagc-tou/${currencyCode || 'usd'}.md`
    )
    setContent(file.data)
  }

  useEffect(() => {
    loadMarkdownn()
  }, [])

  return (
    <div className="max-w-md mx-auto relative">
      <div className="px-4 py-8 prose">
        <Markdown>{content}</Markdown>
      </div>

      <StickyFooter>
        <Button
          className="w-full"
          colorVariant="primary"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </StickyFooter>
    </div>
  )
}

export default GcTerms
