import { useCallback } from 'react'
import socksGrandma from '../assets/grandparents_day/photo_gifts/socks_grandma.png'
import socksGrandpa from '../assets/grandparents_day/photo_gifts/socks_grandpa.png'
import socksGrandpaw from '../assets/grandparents_day/photo_gifts/socks_grandpaw.png'
import socksNana from '../assets/grandparents_day/photo_gifts/socks_nana.png'
import customMug11oz from '../assets/grandparents_day/photo_gifts/mug_11oz.png'
import customMug15oz from '../assets/grandparents_day/photo_gifts/mug_15oz.png'
import customColorfulMug11oz from '../assets/grandparents_day/photo_gifts/colorful_mug_11oz.png'
import customJumboMug20oz from '../assets/grandparents_day/photo_gifts/jumbo_mug_20oz.png'
import customEnamelCampCup from '../assets/grandparents_day/photo_gifts/enamel_camp_cup.png'
import customPuzzle from '../assets/grandparents_day/photo_gifts/puzzle.png'

type Item = {
  src: string
  name: string
  url: string
  rebate: string
}

export type SocksItem = Item

export type PhotoProductItem = Item & {
  blueprintId: number
}

const usePhotoGifts = (printifyProductsListLink: string, uuid: string) => {
  const getPhotoProductItems = useCallback(() => {
    return [
      {
        src: customColorfulMug11oz,
        name: 'Colorful Mug 11oz',
        blueprintId: 1151,
        rebate: '16%',
      },
      {
        src: customPuzzle,
        name: 'Puzzle',
        blueprintId: 616,
        rebate: '20%',
      },
      {
        src: customMug11oz,
        name: 'Mug 11oz',
        blueprintId: 68,
        rebate: '16%',
      },
      {
        src: customMug15oz,
        name: 'Mug 15oz',
        blueprintId: 425,
        rebate: '18%',
      },
      {
        src: customEnamelCampCup,
        name: 'Enamel Camp Cup',
        blueprintId: 1115,
        rebate: '18%',
      },
      {
        src: customJumboMug20oz,
        name: 'Jumbo Mug 20oz',
        blueprintId: 1126,
        rebate: '18%',
      },
    ].map((item) => ({
      ...item,
      url: `${printifyProductsListLink}/add/${item.blueprintId}?uuid=${uuid}`,
    }))
  }, [uuid])

  const socksLandingUrl = `/divvyup-grandparents-day?uuid=${uuid}`
  const getSocksItems = useCallback(() => {
    return [
      {
        src: socksNana,
        name: 'Custom #1 Nana Socks',
        url: socksLandingUrl,
        rebate: '$5',
      },
      {
        src: socksGrandpaw,
        name: 'Custom Grandpaw Socks (Dog Design)',
        url: socksLandingUrl,
        rebate: '$5',
      },
      {
        src: socksGrandpa,
        name: 'Custom #1 Grandpa Socks',
        url: socksLandingUrl,
        rebate: '$5',
      },
      {
        src: socksGrandma,
        name: 'Custom #1 Grandma Socks',
        url: socksLandingUrl,
        rebate: '$5',
      },
    ]
  }, [socksLandingUrl])

  return {
    getPhotoProductItems,
    getSocksItems,
  }
}

export default usePhotoGifts
