import { Link } from 'react-router-dom'

export default function FooterDisclaimer() {
  return (
    <>
      <hr className="my-6" />
      <div className="text-fa-text-secondary text-s">
        <p className="mb-6">
          We use cookies to personalize your site experience and improve our
          service. We do not sell your data to any third parties. By continuing
          to use our site, you agree to our use of cookies,{' '}
          <Link
            className="text-fa-primary"
            target="_blank"
            rel="noreferrer"
            to="https://family-album.com/terms"
          >
            Terms and Conditions
          </Link>
          , and{' '}
          <Link
            className="text-fa-primary"
            target="_blank"
            rel="noreferrer"
            to="https://family-album.com/privacy"
          >
            Privacy Policy
          </Link>
          .
        </p>
        <p>©MIXI</p>
      </div>
    </>
  )
}
