import headerImage from '../../../assets/grandparents_day/special/store_lp_photo_gifts_header.jpg'
import LinkButton from '../../../shared/buttons/LinkButton'
import DetailedCountdown from '../../../shared/DetailedCountdown'
import { C2AMetadata } from '../../../hooks/useRecordUserAction'

export default function PhotoGiftsHeader({
  deadline,
  onCtaClick,
  toPath,
}: {
  deadline: Date
  onCtaClick: (metadata: C2AMetadata) => void
  toPath: string
}) {
  return (
    <section className="bg-[#C1D9BE]">
      <img src={headerImage} className="w-full" />
      <LinkButton
        onClick={() => onCtaClick({ clickedElement: 'LP_HEADER' })}
        className="block p-2 text-center w-11/12 mx-auto rounded-md"
        colorVariant="primary"
        to={toPath}
      >
        Order Photo Gifts
      </LinkButton>
      <div className="w-full text-center p-4">
        <div className="font-bold text-base">Photo Gifts Sale ends in</div>
        <DetailedCountdown
          deadline={deadline}
          variant="cards"
          style={{
            color: '#000000',
            width: '100%',
          }}
        />
      </div>
    </section>
  )
}
