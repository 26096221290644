import { FC } from 'react'
import Lottie from 'lottie-react'
import eGiftsHeader from '../../../animations/grandparents_day/store_lp_egifts_header.json'
import HeaderTitle from '../../../assets/grandparents_day/special/store_lp_egifts_header_title.svg?react'
import LinkButton from '../../../shared/buttons/LinkButton'
import DetailedCountdown from '../../../shared/DetailedCountdown'
import { C2AMetadata } from '../../../hooks/useRecordUserAction'

const EGiftsHeader: FC<{
  deadline: Date
  displayDeadline: boolean
  onCtaClick: (metadata: C2AMetadata) => void
  toPath: string
}> = ({ deadline, displayDeadline, onCtaClick, toPath }) => {
  return (
    <section className="bg-[#FFF9D1] pb-4">
      <div className="w-full mx-auto mb-4">
        <Lottie loop={true} animationData={eGiftsHeader} autoplay />
        <HeaderTitle className="mx-auto mb-4 absolute top-8 left-0 right-0" />
      </div>
      <LinkButton
        onClick={() => onCtaClick({ clickedElement: 'LP_HEADER' })}
        className="block p-2 text-center w-11/12 mx-auto mt-2 rounded-md"
        colorVariant="primary"
        to={toPath}
      >
        Send eGifts & Greetings
      </LinkButton>
      {displayDeadline && (
        <div className="w-full text-center pt-4 px-4">
          <div className="font-bold text-base">Send FREE Greetings in</div>
          <DetailedCountdown
            deadline={deadline}
            variant="cards"
            style={{
              color: '#000000',
              width: '100%',
            }}
          />
        </div>
      )}
    </section>
  )
}

export default EGiftsHeader
