import { ChangeEventHandler, useCallback, useState } from 'react'
import cx from 'classnames'
import Button from '../../../shared/buttons/Button'
import FamilyAlbumPhotosSelectionPanel from '../FamilyAlbumPhotoSelectionPanel'
import PhotoUploadButton from '../FamilyAlbumPhotoSelectionPanel/PhotoUploadButton'
import useFamilyAlbumUserAgent from '../../../hooks/useFamilyAlbumUserAgent'
import { convertFileToBase64 } from '../../../utils'

export default function PhotoSelector({
  onPhotoSelected,
  verticalLayout = false,
  familyAlbumButtonVariant = 'primary:invert',
}: {
  onPhotoSelected: (base64: string) => void
  verticalLayout?: boolean
  familyAlbumButtonVariant?: 'primary' | 'primary:invert'
}) {
  const [isPanelOpen, setIsPanelOpen] = useState(false)

  const openFamilyAlbumPanel = useCallback(() => {
    setIsPanelOpen(true)
  }, [])

  const closeFamilyAlbumPanel = useCallback(() => {
    setIsPanelOpen(false)
  }, [])

  const onPhotoSelectedFromFamilyAlbum = useCallback(
    async (base64: string) => {
      return onPhotoSelected(base64)
    },
    [onPhotoSelected]
  )

  const onPhotoSelectedFromDevice: ChangeEventHandler<HTMLInputElement> = useCallback(
    async (event) => {
      if (!event.target.files || !event.target.files[0]) {
        return
      }

      const base64Original = await convertFileToBase64(event.target.files[0])
      return onPhotoSelected(base64Original)
    },
    [onPhotoSelected]
  )

  const { appVersionSupportsUpload } = useFamilyAlbumUserAgent()
  const showDeviceUpload = appVersionSupportsUpload

  return (
    <>
      <div
        className={cx('grid gap-2', {
          'grid-cols-1': !showDeviceUpload || verticalLayout,
          'grid-cols-2': showDeviceUpload && !verticalLayout,
        })}
      >
        <Button
          colorVariant={familyAlbumButtonVariant}
          className="text-s"
          onClick={openFamilyAlbumPanel}
        >
          From FamilyAlbum
        </Button>
        {showDeviceUpload && (
          <PhotoUploadButton
            colorVariant="primary:invert"
            className="text-s py-2.5"
            onFilesSelected={onPhotoSelectedFromDevice}
          >
            From Device
          </PhotoUploadButton>
        )}
      </div>
      <FamilyAlbumPhotosSelectionPanel
        isOpen={isPanelOpen}
        onClose={closeFamilyAlbumPanel}
        next={onPhotoSelectedFromFamilyAlbum}
      />
    </>
  )
}
