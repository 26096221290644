import cx from 'classnames'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import storeLpGiftCards from '../../../assets/grandparents_day/special/store_lp_gift_cards.png'
import DetailedCountdown from '../../../shared/DetailedCountdown'
import LinkButton from '../../../shared/buttons/LinkButton'
import { C2AMetadata } from '../../../hooks/useRecordUserAction'
import SectionHeader from './SectionHeader'

const DGCS = [
  'grandparentsday_09',
  'grandparentsday_31',
  'grandparentsday_30',
  'grandparentsday_16',
  'grandparentsday_11',
  'grandparentsday_32',
]

const EGiftsSection: FC<{
  order: number
  deadline: Date
  displayDeadline: boolean
  backgroundClass: string
  toPath: string
  onCtaClick: (metadata: C2AMetadata) => void
}> = ({
  order,
  deadline,
  displayDeadline,
  backgroundClass,
  toPath,
  onCtaClick,
}) => {
  return (
    <section className={cx('pb-12 pt-4', backgroundClass)}>
      <SectionHeader order={order} title="FREE Greetings">
        Send heartfelt love and joy near or far with our <b>FREE</b> digital
        greetings
      </SectionHeader>
      <Link
        onClick={() =>
          onCtaClick({
            clickedElement: 'IMAGE',
            slot: order.toString(),
          })
        }
        to={toPath}
      >
        <ul className="grid grid-cols-3 gap-2 mx-auto px-2 mt-2">
          {DGCS.map((coverImageFileName, index) => (
            <div
              key={`dgc${index}`}
              className="h-40 rounded-lg justify-center items-start gap-2 inline-flex"
            >
              <img
                className="shrink basis-0 self-stretch rounded-lg shadow"
                src={`grandparents_day_dgc/${coverImageFileName}_thumb.jpg`}
                alt={coverImageFileName}
              />
            </div>
          ))}
        </ul>
      </Link>
      <div className="text-right mt-4 mb-6 px-2">
        <Link
          onClick={() => {
            onCtaClick({
              clickedElement: 'LP_SEE_MORE',
              slot: order.toString(),
            })
          }}
          className="font-bold text-fa-primary leading-snug"
          to={toPath}
        >
          See more
        </Link>
      </div>
      <div className="space-y-4 mb-6">
        <h2 className="text-center font-semibold w-8/12 text-xl mx-auto text-[#0D3D38]">
          Make their day by including <br /> an optional eGift card too!
        </h2>
        <img src={storeLpGiftCards} alt="Cards" />
      </div>
      {displayDeadline && (
        <div className="w-full text-center mb-6">
          <div className="font-bold text-base">Send FREE Greetings in</div>
          <DetailedCountdown
            deadline={deadline}
            style={{
              color: '#000000',
              width: '100%',
            }}
          />
        </div>
      )}
      <LinkButton
        onClick={() =>
          onCtaClick({
            clickedElement: 'LP_SECTION',
            slot: order.toString(),
          })
        }
        className="block font-bold p-2 text-center w-11/12 mx-auto mt-2 rounded-md"
        colorVariant="primary"
        to={toPath}
      >
        Send eGifts & Greetings
      </LinkButton>
    </section>
  )
}

export default EGiftsSection
