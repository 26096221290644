import { Link, LinkProps } from 'react-router-dom'
import cx from 'classnames'
import { ButtonVariant, buttonVariants } from './config'

export default function LinkButton({
  children,
  className,
  colorVariant,
  ...props
}: LinkProps & { colorVariant: ButtonVariant }) {
  return (
    <Link
      {...props}
      className={cx(
        'font-bold text-base rounded tracking-tight',
        buttonVariants[colorVariant],
        className
      )}
    >
      {children}
    </Link>
  )
}
