export default function ShoppingCartIcon({
  className,
}: {
  className?: string
}) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 23C7.45 23 6.97917 22.8042 6.5875 22.4125C6.19583 22.0208 6 21.55 6 21C6 20.45 6.19583 19.9792 6.5875 19.5875C6.97917 19.1958 7.45 19 8 19C8.55 19 9.02083 19.1958 9.4125 19.5875C9.80417 19.9792 10 20.45 10 21C10 21.55 9.80417 22.0208 9.4125 22.4125C9.02083 22.8042 8.55 23 8 23ZM18 23C17.45 23 16.9792 22.8042 16.5875 22.4125C16.1958 22.0208 16 21.55 16 21C16 20.45 16.1958 19.9792 16.5875 19.5875C16.9792 19.1958 17.45 19 18 19C18.55 19 19.0208 19.1958 19.4125 19.5875C19.8042 19.9792 20 20.45 20 21C20 21.55 19.8042 22.0208 19.4125 22.4125C19.0208 22.8042 18.55 23 18 23ZM7.15 7L9.55 12H16.55L19.3 7H7.15ZM6.2 5H20.95C21.3333 5 21.625 5.17083 21.825 5.5125C22.025 5.85417 22.0333 6.2 21.85 6.55L18.3 12.95C18.1167 13.2833 17.8708 13.5417 17.5625 13.725C17.2542 13.9083 16.9167 14 16.55 14H9.1L8 16H19C19.2833 16 19.5208 16.0958 19.7125 16.2875C19.9042 16.4792 20 16.7167 20 17C20 17.2833 19.9042 17.5208 19.7125 17.7125C19.5208 17.9042 19.2833 18 19 18H8C7.25 18 6.68333 17.6708 6.3 17.0125C5.91667 16.3542 5.9 15.7 6.25 15.05L7.6 12.6L4 5H3C2.71667 5 2.47917 4.90417 2.2875 4.7125C2.09583 4.52083 2 4.28333 2 4C2 3.71667 2.09583 3.47917 2.2875 3.2875C2.47917 3.09583 2.71667 3 3 3H4.625C4.80833 3 4.98333 3.05 5.15 3.15C5.31667 3.25 5.44167 3.39167 5.525 3.575L6.2 5Z"
        fill="#202020"
      />
    </svg>
  )
}
