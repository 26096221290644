import {
  FingerPrintIcon,
  HeartIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/solid'
import { isSameDay } from 'date-fns'
import Lottie from 'lottie-react'
import { FC, useCallback, useMemo } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import toast from 'react-hot-toast'
import ApiClient from '../../ApiClient'
import animationDataWithout10Off from '../../animations/LOOP_mothers_day_animated.json'
import animationDataWith10Off from '../../animations/LOOP_mothers_day_animated_10_OFF.json'
import CopyToClipboardIcon from '../../assets/copy-to-clipboard-icon.svg?react'
import FaXLoopLogos from '../../assets/loop_mothers_day/fa_x_loop_logos.svg?react'
import loop10Off from '../../assets/loop_mothers_day/loop_10_off.jpg'
import loop10OffCoupon from '../../assets/loop_mothers_day/loop_10_off_coupon.png'
import loop10OffLastDay from '../../assets/loop_mothers_day/loop_10_off_final_day.jpg'
import loopLastShippingDay from '../../assets/loop_mothers_day/loop_last_ship.jpg'
import limitedOfferSectionBackground from '../../assets/loop_mothers_day/loop_mom_bg.jpg'
import loopWhy1Content from '../../assets/loop_mothers_day/loop_mother_1.png'
import loopWhy2Content from '../../assets/loop_mothers_day/loop_mother_2.png'
import loopWhy3Content from '../../assets/loop_mothers_day/loop_mother_3.png'
import HeaderParagraph from '../../assets/loop_mothers_day/lottie_header_paragraph.svg?react'
import MakeMomSmileHeader from '../../assets/loop_mothers_day/make_mom_smile-header.svg?react'
import DetailedCountdown from '../../shared/DetailedCountdown'
import FaqItem from '../../shared/FaqItem'
import FooterDisclaimer from '../../shared/FooterDisclaimer'
import StickyFooter from '../../shared/StickyFooter'
import Button from '../../shared/buttons/Button'
import LinkButton from '../../shared/buttons/LinkButton'
import useRecordUserAction from '../../hooks/useRecordUserAction'
import {
  COUPON_CODE_QUERY_PARAM_KEY,
  LOCAL_STORAGE_CART_ID_KEY,
} from './LoopStore'

const faqItems = [
  {
    question: 'How do I connect Loop with my FamilyAlbum account?',
    answer:
      'Connecting Loop with your FamilyAlbum account is a breeze—complete the Loop photo frame setup and download the Loop app to connect your FamilyAlbum account. Your personal favorites in the FamilyAlbum app will automatically sync with your Loop frame each day.',
  },
  {
    question: 'Can I select specific photos from FamilyAlbum?',
    answer:
      'Loop is set up to automatically sync to your Favorites in FamilyAlbum. If you want a photo to appear on Loop, just add it to your Favorites by selecting the star icon in the FamilyAlbum app.',
  },
  {
    question: 'Can I select photos from my phone?',
    answer:
      "Yes! Use the Loop app to handpick photos or opt for hassle-free automatic sync with your FamilyAlbum favorites. You're in control—choose multiple albums for a frame, blending your favorites with cherished moments effortlessly.",
  },
  {
    question: 'Can I gift a Loop frame with pre-loaded FamilyAlbum photos?',
    answer:
      "Absolutely! When gifting a Loop frame, it's recommended to set it up and pre-load it with FamilyAlbum photos for an optimal unboxing experience. The gift receiver will only have to connect the frame to their local Wi-Fi to start seeing photos sync automatically from FamilyAlbum. You'll then be able to remotely update the photos displayed on the frame any time you'd like, and the recipient won’t need to download the app either!",
  },
  {
    question: 'Does Loop play videos?',
    answer:
      'Yes! Loop brings your photos and videos to life, offering dynamic displays for short clips or longer videos, ensuring an engaging showcase of your family memories.',
  },
  {
    question: 'Does Loop need a WiFi connection?',
    answer:
      'Loop requires WiFi for initial setup and automatic syncs with new photos and videos from FamilyAlbum. That said, no WiFi is required to keep showing photos and videos already loaded to Loop. If you choose to gift Loop pre-loaded with photos and no need for automatic updates, there should be no problem using without WiFi.',
  },
]

const NOW_DATE = new Date()
const TEN_PERCENT_OFF_DEADLINE_DATE = '2024-05-03T11:59:59.000-07:00' // 10% off ends on May 3rd, 2024 - 11:59:59 AM PST
const MOTHERS_DAY_DEADLINE_DATE = '2024-05-07T23:59:59'

enum Prefix {
  EndsIn = 'Ends In: ',
  Empty = '',
  OrderWithin = 'Order within',
}

const shouldShow10PercentOff = (now: Date, tenPercentOffDeadline: Date) =>
  now.getTime() < tenPercentOffDeadline.getTime()
const isLast10PercentOffDay = (now: Date, tenPercentOffDeadline: Date) =>
  isSameDay(now, tenPercentOffDeadline) &&
  now.getTime() < tenPercentOffDeadline.getTime()

const now = new Date(NOW_DATE)
const tenPercentOffDeadline = new Date(TEN_PERCENT_OFF_DEADLINE_DATE)
const mothersDayDeadline = new Date(MOTHERS_DAY_DEADLINE_DATE)

const show10PercentOff = shouldShow10PercentOff(now, tenPercentOffDeadline)
const last10PercentOffDay = isLast10PercentOffDay(now, tenPercentOffDeadline)

type Config = {
  animationData: any
  deadline: Date
  prefix: Prefix
  countdownBackground: string
}

let config: Config = {
  animationData: animationDataWithout10Off,
  deadline: mothersDayDeadline,
  prefix: Prefix.OrderWithin,
  countdownBackground: loopLastShippingDay,
}

if (last10PercentOffDay || show10PercentOff) {
  config = {
    ...config,
    animationData: animationDataWith10Off,
    deadline: tenPercentOffDeadline,
    countdownBackground: last10PercentOffDay ? loop10OffLastDay : loop10Off,
  }

  if (last10PercentOffDay) {
    config.prefix = Prefix.Empty
  } else {
    config.prefix = Prefix.EndsIn
  }
}

const LoopMothersDay: FC<{
  api: ApiClient
  supportEmail: string
  couponCode?: string
}> = ({ api, supportEmail, couponCode }) => {
  const { recordLoopC2ATap, recordDidCopyLoopPromoCodeTap } =
    useRecordUserAction(api)

  const goToLoopStoreLp = useCallback(() => {
    window.scrollTo(0, 0)
    localStorage.removeItem(LOCAL_STORAGE_CART_ID_KEY)
    recordLoopC2ATap()
  }, [recordLoopC2ATap])

  const onCopy = useCallback(() => {
    recordDidCopyLoopPromoCodeTap()
    toast.success('Copied!')
  }, [recordDidCopyLoopPromoCodeTap])

  const toPath = useMemo(
    () =>
      `/loop-store${
        couponCode ? `?${COUPON_CODE_QUERY_PARAM_KEY}=${couponCode}` : ''
      }`,
    [couponCode]
  )

  const { animationData, deadline, prefix, countdownBackground } = config
  const showCountdown = now.getTime() < deadline.getTime()

  return (
    <div className="max-w-lg mx-auto">
      {showCountdown && (
        <div className="relative">
          <img src={countdownBackground} alt="" />
          <div className="absolute bottom-2 w-full">
            <DetailedCountdown
              deadline={deadline}
              prefix={prefix}
              style={{
                color: '#1C394F',
                width: '100%',
              }}
            />
          </div>
        </div>
      )}
      <div className="relative">
        <div className="absolute w-full xs:top-8 top-12 z-10 space-y-6">
          <MakeMomSmileHeader className="w-full" />
          <HeaderParagraph className="w-full" />
        </div>
        <Lottie loop={true} animationData={animationData} autoplay />
      </div>
      <section className="px-4 mt-8 text-center space-y-4">
        <FaXLoopLogos className="mx-auto" />
        <p className="px-8">
          Loop is the only digital photo frame <br />
          compatible with the FamilyAlbum app!
        </p>
      </section>
      <section className="px-4 py-12 text-center space-y-8">
        <div>
          <img
            src={loopWhy1Content}
            alt="Set up in 1 min"
            className="mx-auto mb-4"
          />
          <h3 className="font-semibold text-xl mb-2 text-[#1D3E56]">
            Set up in 1 min
          </h3>
          <p className="text-fa-text-primary tracking-wider">
            Connect Loop to Wi-Fi and enable <br />
            automatic sync with FamilyAlbum in <br />
            the Loop app.
          </p>
        </div>
        <div>
          <img
            src={loopWhy2Content}
            alt="Add your favorite photos"
            className="mx-auto mb-4"
          />
          <h3 className="font-semibold text-xl mb-2 text-[#1D3E56]">
            Add your favorite photos
          </h3>
          <p className="text-fa-text-primary tracking-wider">
            Mark favorites in FamilyAlbum for <br /> seamless syncing to your
            frame at home <br /> or those you've gifted!
          </p>
        </div>
        <div>
          <img
            src={loopWhy3Content}
            alt="Share and cherish moments"
            className="mx-auto mb-4"
          />
          <h3 className="font-semibold text-xl mb-2 text-[#1D3E56]">
            Share and cherish moments
          </h3>
          <p className="text-fa-text-primary tracking-wider">
            Delight your loved ones with a <br /> surprise gift that keeps on
            giving!
          </p>
        </div>
      </section>
      <section>
        {show10PercentOff && (
          <div
            className="py-12 px-8 bg-center bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${limitedOfferSectionBackground})`,
            }}
          >
            <div className="bg-white drop-shadow-2xl px-6 py-8 text-center">
              <h3 className="font-bold text-2xl mb-4">
                Limited time Mother’s Day offer
              </h3>
              <div className="mb-4">
                <DetailedCountdown
                  deadline={tenPercentOffDeadline}
                  prefix="Ends In: "
                  style={{
                    color: '#212121',
                    width: '100%',
                  }}
                />
              </div>
              <img
                src={loop10OffCoupon}
                alt="HAPPYMOM coupon code"
                className="w-32 mx-auto mb-4"
              />
              <p className="mb-6 text-lg leading-7 font-['SF Pro']">
                Order today & get 10% OFF. <br />
                Use coupon code below:
              </p>
              {couponCode && (
                <CopyToClipboard text={couponCode} onCopy={onCopy}>
                  <Button
                    colorVariant="primary:invert"
                    className="font-normal rounded-full text-base px-8 py-2 flex items-center mx-auto"
                  >
                    <CopyToClipboardIcon />
                    {couponCode}
                  </Button>
                </CopyToClipboard>
              )}
            </div>
          </div>
        )}
      </section>
      <section className="bg-fa-bg-blue p-6 px-12 flex flex-col items-center">
        <h2 className="font-semibold text-2xl my-6">Why Loop?</h2>
        <ul className="text-left w-full">
          <li className="flex items-center mb-2">
            <FingerPrintIcon className="w-5 h-5 mr-2" />
            <span className="ml-2">Easy-to-use touchscreen</span>
          </li>
          <li className="flex items-center mb-2">
            <HeartIcon className="w-5 h-5 mr-2" />
            <span className="ml-2">No tech-savvy skills needed</span>
          </li>
          <li className="flex items-center">
            <VideoCameraIcon className="w-5 h-5 mr-2" />
            <span className="ml-2">Loop plays photos &amp; videos too!</span>
          </li>
        </ul>
        <p className="my-6 text-gray-700 text-center">
          For those who aren’t tech savvy, Loop offers a perfect way to stay
          connected without a smartphone.
        </p>
      </section>
      <section className="px-4 py-12">
        <h2 className="font-bold text-2xl mb-4">FAQ</h2>
        <div className="text-fa-text-secondary space-y-8">
          {faqItems.map((item) => (
            <FaqItem
              key={item.question}
              question={item.question}
              answer={item.answer}
            />
          ))}
        </div>
      </section>
      <footer className="px-4 pb-8">
        <p>
          Have questions? Contact us at <br />
          <a className="text-fa-primary" href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>
        </p>
        <FooterDisclaimer />
      </footer>
      <StickyFooter>
        <LinkButton
          onClick={goToLoopStoreLp}
          className="block p-2"
          colorVariant="primary"
          to={toPath}
        >
          Get started
        </LinkButton>
      </StickyFooter>
    </div>
  )
}

export default LoopMothersDay
