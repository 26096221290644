import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import DialogCloseButton from './DialogCloseButton'
import Button from './buttons/Button'
import { ReactNode, useCallback } from 'react'
import { ButtonVariant } from './buttons/config'

export default function ConfirmationDialog({
  isOpen,
  close,
  confirm,
  message,
  title,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  confirmButtonVariant = 'primary',
  hideCloseButton = false,
  afterLeave,
}: {
  isOpen: boolean
  close: (source?: 'GENERIC' | 'CANCEL') => void
  confirm: () => void
  message?: ReactNode
  title?: string
  cancelButtonText?: string
  confirmButtonText?: string
  confirmButtonVariant?: ButtonVariant
  hideCloseButton?: boolean
  afterLeave?: () => void
}) {
  const onClose = useCallback(
    (source?: 'GENERIC' | 'CANCEL') => {
      close(source)
    },
    [close]
  )

  return (
    <Transition show={isOpen} afterLeave={afterLeave}>
      <Dialog onClose={() => onClose('GENERIC')} className="relative z-50">
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" />
        </TransitionChild>

        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
            <DialogPanel className="bg-white relative w-full md:max-w-[400px] rounded-lg p-8 overflow-auto">
              {!hideCloseButton && (
                <DialogCloseButton
                  className="absolute top-3 right-3"
                  onClick={() => onClose('GENERIC')}
                />
              )}
              <div className="mb-6 text-center space-y-2">
                {title && <p className="font-bold">{title}</p>}
                {message && <p>{message}</p>}
              </div>
              <div className="grid grid-cols-2 gap-4">
                <Button
                  className="rounded-lg py-2"
                  colorVariant="primary:invert"
                  onClick={() => onClose('CANCEL')}
                >
                  {cancelButtonText}
                </Button>

                <Button
                  className="rounded-lg py-2"
                  colorVariant={confirmButtonVariant}
                  onClick={() => confirm()}
                >
                  {confirmButtonText}
                </Button>
              </div>
            </DialogPanel>
          </div>
        </TransitionChild>
      </Dialog>
    </Transition>
  )
}
